<template>
  <van-uploader
    :accept="accept"
    :multiple="multiple"
    :max-count="maxCount"
    :max-size="maxSize"
    :before-read="beforeRead"
    :after-read="onRead"
    :result-type="resultType"
    @oversize="onOversize"
  />
</template>

<script>
import { renderSize } from '@filters'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Uploader',

  props: {
    // 允许上传的文件类型
    accept: {
      type: String,
      default: () => 'image/jpg'
    },

    // 是否开启图片多选，部分安卓机型不支持
    multiple: {
      type: Boolean,
      default: () => true
    },

    // 允许上传的文件类型
    maxCount: {
      type: Number,
      default: () => 9
    },

    // 文件大小限制，单位为 byte
    maxSize: {
      type: Number,
      default: () => 2 * 1024 * 1024
    },

    // 上传类型
    limitTypeName: {
      type: String,
      default: () => 'jpeg、jpg',
      required: false
    },

    // 上传类型
    limitType: {
      type: Array,
      default: () => ['jpeg', 'jpg'],
      required: false
    },

    // 文件读取结果类型
    resultType: {
      type: String,
      default: () => 'file',
      required: false
    },

    // 消息类型
    messageType: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      message: '',
      showContent: false
    }
  },

  computed: {
    ...mapState('oss', ['failureTime', 'baseUrl'])
  },

  methods: {
    ...mapActions('oss', ['putObject', 'getToken']),
    /**
     * 限制上传大小
     * @param  {file} file 文件信息
     */
    onOversize (file) {
      this.$toast('文件大小不能超过' + renderSize(this.maxSize, 0))
    },

    /**
     * 上传前进行校验和处理
     * @param  {file} file 文件信息
     */
    beforeRead (file) {
      return new Promise((resolve, reject) => {
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true
        })
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        const { limitType, limitTypeName } = this
        if (limitTypeName) {
          const isTrueType = limitType.indexOf(extension) > -1
          if (!isTrueType) {
            this.$toast(`上传文件只能是 ${limitTypeName} 格式`)
            /* eslint-disable */
            reject()
            /* eslint-enable */
          }
        }
        // stsToken过期 重新获取
        if (new Date(this.failureTime) < new Date()) {
          this.getToken().then(() => {
            resolve(file)
          })
        }
        resolve(file)
      })
    },

    /**
     * 发送图片
     * @param  {file} file 文件信息
     */
    onRead (file) {
      const { IMAGE } = this.$globalData.MESSAGE_TYPE
      // 文件上传oss
      this.putObject(file).then(data => {
        let msgContent
        // 图片消息
        if (this.messageType === IMAGE) {
          // image转base64
          this.$common.imageToBase64(file).then(base64 => {
            this.$toast.clear()
            msgContent = {
              content: base64,
              imageUri: data.url
            }
            this.$emit('getInfo', this.messageType, msgContent)
          })
        } else {
          // 文件消息
          const { name, size, type } = file
          msgContent = {
            name,
            size,
            type,
            fileUrl: data.url
          }
          this.$emit('getInfo', this.messageType, msgContent)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
