/**
 * @author lulu Wang
 * @date 2020/11/18
 * @Description 定义项目所需常量
*/

const TIMEOUT = 60000
const PAGE_SIZE = 15
const RET_CODE = {
  SUCCESS: 1,
  FAIL: -1
}
const bannerTypeObj = {
  url: 1,
  id: 2,
  bottom: 3,
  no: 4
}
export default {
  TIMEOUT,
  PAGE_SIZE,
  RET_CODE,
  bannerTypeObj
}
