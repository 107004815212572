<template>
    <div class="side-float">
      <div class="side-float-item" v-for="(item,index) in dataList" :key="index">
        <div @click="clickIcon(item)">
          <el-image
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            class="info-image"
            :src="item.url"
            fit="cover"
          ></el-image>
        </div>
      </div>
<!--      加入我们悬浮框-->
      <div class="join-us-float" v-if="joinUs" @mouseleave="leave(0)">
        <span>加入我们</span>
        <span class="join-us-float-triangle"></span>
      </div>
<!--      联系电话浮窗-->
      <div class="contact-phone" @mouseleave="leave(1)" v-if="contactPhone">
        <div class="margin-top-15 contact-phone-item" v-for="(item,index) in contactList" :key="index">
          <div class="flex-start-item">
            <el-image :src="item.icon" class="contact-phone-icon"></el-image>
            <div class="contact-phone-title">{{item.title}}</div>
          </div>
          <div v-if="item.text" class="contact-phone-text">{{item.text}}</div>
          <el-image v-else :src="item.img" class="contact-phone-img"></el-image>
        </div>
        <div class="contact-phone-triangle"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SideFloat',
  data () {
    return {
      joinUs: false,
      contactPhone: false,
      dataList: [
        {
          name: 'join',
          url: require('@images/produce/ic_common_sidebar_joinus.png')
        },
        {
          name: 'contact',
          url: require('@images/produce/ic_common_sidebar_contactus.png')
        },
        {
          name: 'cooperation',
          url: require('@images/produce/ic_common_sidebar_cooperation.png')
        },
        {
          name: 'download',
          url: require('@images/produce/ic_common_sidebar_download.png')
        },
        {
          name: 'top',
          url: require('@images/produce/ic_common_sidebar_top.png')
        }
      ],
      urlList: [
        {
          hoverUrl: require('@images/produce/ic_common_sidebar_joinus_h.png'),
          normalUrl: require('@images/produce/ic_common_sidebar_joinus.png')
        },
        {
          hoverUrl: require('@images/produce/ic_common_sidebar_contactus_h.png'),
          normalUrl: require('@images/produce/ic_common_sidebar_contactus.png')
        },
        {
          hoverUrl: require('@images/produce/ic_common_sidebar_cooperation_h.png'),
          normalUrl: require('@images/produce/ic_common_sidebar_cooperation.png')
        },
        {
          hoverUrl: require('@images/produce/ic_common_sidebar_download_h.png'),
          normalUrl: require('@images/produce/ic_common_sidebar_download.png')
        },
        {
          hoverUrl: require('@images/produce/ic_common_sidebar_top_h.png'),
          normalUrl: require('@images/produce/ic_common_sidebar_top.png')
        }
      ],
      contactList: [
        { icon: require('@images/produce/ic_common_sidebar_phone.svg'), title: '联系电话：', text: '0571-81903089' },
        { icon: require('@images/produce/ic_common_sidebar_email.svg'), title: '邮箱：', text: 'XXXXXXXX@163.com' },
        { icon: require('@images/produce/ic_common_sidebar_email.svg'), title: '微信公众号：', img: require('@images/produce/ic_common_sidebar_joinus.png') }
      ]
    }
  },
  methods: {
    enter (index) {
      this.dataList[index].url = this.urlList[index].hoverUrl
      if (index === 0) this.joinUs = true
      if (index === 1) this.contactPhone = true
    },
    leave (index) {
      this.dataList[index].url = this.urlList[index].normalUrl
      if (index === 0) this.joinUs = false
      if (index === 1) this.contactPhone = false
    },
    clickIcon (item) {
      if (item.name === 'top') {
        this.backtop()
      } else if (item.name === 'download') {
        window.open('http://share.zjems.com.cn')
      }
    },
    backtop () {
      var timer = setInterval(function () {
        const osTop = document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        // this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
  }
}
</script>

<style lang="less" scoped>
.side-float {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  .side-float-item {
    margin-bottom: 13px;
    .info-image {
      width:56px;
      height: 56px;
      border-radius: 50%;
    }
  }
  .join-us-float {
    width: 100px;
    height: 52px;
    font-size: 16px;
    color: #747EA3;
    text-align: center;
    line-height: 52px;
    background: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(63,99,232,0.15);
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 70px;
    .join-us-float-triangle {
      display: inline-block;
      position: absolute;
      top: 15px;
      right: -20px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid #FFFFFF;
    }
  }
  .contact-phone {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(63,99,232,0.15);
    border-radius: 8px;
    position: absolute;
    top: 70px;
    right: 70px;
    .contact-phone-item:first-child {
      margin-top: 0;
    }
    .contact-phone-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    .contact-phone-title {
      font-size: 16px;
      color: #747EA3;
    }
    .contact-phone-text {
      margin-top: 5px;
      font-size: 18px;
      color: #F58F14;
      font-weight: bold;
    }
    .contact-phone-img {
      width: 100px;
      height: 100px;
      margin-top: 15px;
    }
    .contact-phone-triangle {
      display: inline-block;
      position: absolute;
      top: 25px;
      right: -20px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid #FFFFFF;
    }
  }
}
</style>
