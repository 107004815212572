const state = {
  userInfo: null
}

const mutations = {
  SET_USER: (state, userInfo) => {
    state.userInfo = userInfo
  }
}

const actions = {
  getUser ({ commit }, userInfo) {
    commit('SET_USER', userInfo)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
