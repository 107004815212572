import Vue from 'vue';
import Router from 'vue-router';;
Vue.use(Router);
export const routes = [{
    component: () => import('@/views/index/index.vue'),
    name: 'index',
    path: '/index',
    children: [],
  },
  {
    component: () => import('@/views/produce/index.vue'),
    name: 'produce',
    path: 'produce',
  },
  {
    component: () => import('@/views/produce/dispatcher/index.vue'),
    name: 'produce-dispatcher',
    path: 'produce/dispatcher',
  },
  {
    component: () => import('@/views/produce/zlb/index.vue'),
    name: 'produce-zlb',
    path: 'produce/zlb',
  },
  {
    path: '/',
    redirect: '/index'
  },
];
const router = new Router({
  mode: 'hash',
  routes,
});
export default router;