<template>
  <div class="title-box flex-center-item" :style="{marginTop: marginTop + 'px'}">
    <img :src="leftPic" alt="" class="title-pic"><span class="title" :style="{color: fontColor}">{{title}}</span><img :src="rightPic" alt="" class="title-pic">
  </div>
</template>

<script>
export default {
  name: 'ModuleTitle',

  props: {
    title: {
      type: String,
      require: true
    },
    fontColor: {
      type: String,
      require: false,
      default: () => '#061549'
    },
    marginTop: {
      type: Number,
      require: false,
      default: () => 60
    }
  },

  data () {
    return {
      leftPic: require('@images/title/pic_home_title_left.svg'),
      rightPic: require('@images/title/pic_home_title_right.svg')
    }
  }
}
</script>

<style lang="less" scoped>
  @media screen and (min-width: 750px) {
    .title {
      font-size: 32px;
      font-weight: bold;
      color: #061549;
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 750px) {
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #061549;
      padding: 0 15px;
      white-space: nowrap;
    }
    .title-pic{
      height: 16px;
    }
  }
  .title-box {
    margin-top: 60px;
  }
</style>
