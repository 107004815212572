import axios from 'axios'
import constant from '@/constant'
import { Message } from 'element-ui'
import store from '@/store'
import router from 'vue-router'
const token = ''
// create an axios instance
const service = axios.create({
  timeout: constant.TIMEOUT
})
axios.defaults.headers.common.Authorization = token
// request interceptor
service.interceptors.request.use(
  config => {
    const token = store.state.session.userInfo
      ? store.state.session.userInfo.token
      : ''
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    console.error('requestErr：' + error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // 5002 登录超时 401 没有登录
    if (response.data.retCode === 5002 || response.data.retCode === 401) {
      Message.error({
        message: response.data.msg.prompt,
        duration: 5 * 1000
      })
      store.dispatch('session/getUser', null)
      router.push('/')
    }
    return response
  },
  error => {
    console.error('responseErr：' + error)
    Message.error({
      message: '请求失败',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
