import Vue from 'vue'
import Vuex from 'vuex'
import session from './modules/session'
import local from './modules/local'
import app from './modules/app'
import getters from './getters'
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const sessionPersisted = persistedState({
  storage: window.sessionStorage,
  reducer (val) {
    return {
      session: val.session,
      oss: val.oss
    }
  }
})
const localPersisted = persistedState({
  storage: window.localStorage,
  reducer (val) {
    return {
      local: val.local
    }
  }
})
export default new Vuex.Store({
  getters,
  modules: {
    session,
    app,
    local
  },
  plugins: [sessionPersisted, localPersisted]
})
