import Vue from 'vue'
import request from './request'
/**
 *ajax的封装（post）
 * @param funcUrl  请求的方法地址
 * @param argsData 入参
 */
const service = (funcUrl, argsData) => {
  const UA = navigator.userAgent
  const TOKEN = 'dreamtouch'
  return request({
    url: funcUrl,
    method: 'post',
    data: {
      args: argsData,
      token: TOKEN,
      deviceInfo: UA,
      channel: 'wx'
    }
  })
}
export default {
  service
}
// 将services挂载到vue的原型上
Vue.prototype.$services = service
