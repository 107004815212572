import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import { Button, Message, MessageBox, Image, Tabs, TabPane, Row, Col, Divider, RadioGroup, RadioButton, Input, Select, Option, Tooltip, Container, Aside, Main, Menu, MenuItem, MenuItemGroup, Icon, Submenu, Drawer, Backtop, Popover } from 'element-ui'

Vue.use(Button)
Vue.use(Image)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tooltip)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Icon)
Vue.use(Submenu)
Vue.use(Drawer)
Vue.use(Backtop)
Vue.use(Popover)
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
