<template>
  <div class="footer" :class="isMobile?'footer-' + isMobile:''">
    <template v-if="isMobile">
      <div class="margin-bottom-5 flex--all-start-item padding-aside-15">
        <span class="white-space-nowrap">友情链接：</span>
        <span class="flex--all-start-item flex-wrap-item">
          <a class="footer-url margin-right-10" href="https://wsjkw.zj.gov.cn/" target="_blank">浙江省卫生健康委员会</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="http://www.zjems.org/front/index" target="_blank">浙江省急救指挥中心</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="http://www.hospitalstar.com/" target="_blank">浙江省人民医院</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="http://www.zju.edu.cn/" target="_blank">浙江大学</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="https://www.zust.edu.cn/" target="_blank">浙江科技学院</a>
        </span>
      </div>
      <div class="margin-bottom-5 padding-aside-15">联系电话：0571-81903089</div>
      <div class="margin-bottom-5 padding-aside-15">邮箱：hz_dreamtouch@163.com</div>
      <div class="padding-aside-15">公司地址：浙江省杭州市西湖区留下街道汇峰国际D座503</div>
      <el-divider style="margin: 13px 0"></el-divider>
      <div class="font-size-12 padding-aside-15">Copyright@2011-2021 www.dreamtouch.com.cn,All rights reserved <span class="margin-right-10"></span>Powered by 杭州触梦智能科技有限公司 版权所有</div>
      <div class="font-size-12 padding-aside-15" style="margin-top: 5px">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011878" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img :src="require('@images/global/ba.png')" style="float:left"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33010602011878号</p></a>
      </div>
      <div class="font-size-12 padding-aside-15"> <a class="footer-url" href="http://beian.miit.gov.cn/" target="_blank">浙ICP备16046486号-1</a> <a style="margin: 2px 0 0 2px;" href="https://www.cnzz.com/stat/website.php?web_id=1279449492" target="_blank" title="站长统计"><img src="@images/global/pic_statistics.gif"></a></div>
    </template>
    <template v-else>
      <div class="margin-bottom-10">
        友情链接：  <a class="footer-url margin-right-10" href="https://wsjkw.zj.gov.cn/" target="_blank">浙江省卫生健康委员会</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="http://www.zjems.org/front/index" target="_blank">浙江省急救指挥中心</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="http://www.hospitalstar.com/" target="_blank">浙江省人民医院</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="http://www.zju.edu.cn/" target="_blank">浙江大学</a><span class="margin-right-10">｜</span><a class="footer-url margin-right-10" href="https://www.zust.edu.cn/" target="_blank">浙江科技学院</a>
      </div>
      <div><span class="margin-right-40">联系电话：0571-81903089</span><span class="margin-right-40">邮箱：hz_dreamtouch@163.com</span>公司地址：浙江省杭州市西湖区留下街道汇峰国际D座503</div>
      <el-divider></el-divider>
      <div class="font-size-14">Copyright@2011-2021 www.dreamtouch.com.cn,All rights reserved <span class="margin-right-10"></span>Powered by 杭州触梦智能科技有限公司 版权所有</div>
      <div class="font-size-14 flex-center-item">
        <a class="footer-url flex-start-item" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011878"><img :src="require('@images/global/ba.png')"/><span style="margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33010602011878号</span></a>
        <a class="footer-url" style="margin-left: 10px" href="http://beian.miit.gov.cn/" target="_blank">浙ICP备16046486号-1</a> <a style="margin: 2px 0 0 2px;" href="https://www.cnzz.com/stat/website.php?web_id=1279449492" target="_blank" title="站长统计"><img src="@images/global/pic_statistics.gif"></a></div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  data () {
    return {
      device: sessionStorage.getItem('device')
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  }
}
</script>
<style scoped lang="less">
  .footer{
    color: #CCCCCC;
    font-size: 16px;
    background: #061549;
    word-break: break-all;
    text-align: center;
    padding: 20px 0;
    &-true{
      text-align: left;
      padding: 15px 0;
      font-size: 14px;
    }
  }
  .el-divider{
    background-color: #9DA6C9;
    margin: 15px 0;
  }
  .footer-url{
    &:hover{
      color: #2E7ED9;
    }
  }
</style>
