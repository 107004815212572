<template>
  <div id="app">
    <Header />
    <router-view class="main-container"/>
    <Footer />
  </div>
</template>
<script>
import { Header, Footer } from '@components'
export default {
  name: 'app',

  components: {
    Header,
    Footer
  },

  created () {},

  methods: {
  }
}
</script>
<style lang="less">
  @media screen and (min-width: 750px) {
    #app{
      min-width: 1240px;
    }
  }
  .main-container {
    min-height: 80vh;
    background: rgba(63, 99, 232, 0.03);
  }
</style>
