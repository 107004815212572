/**
 * @author lulu Wang
 * @date 2020/10/28
 * @Description: 定义过滤器模块
*/
import Vue from 'vue'
/**
 * 字符串过长过滤
 * @param  {string} value 原数据
 * @param  {number} length 超过length截断
 * @return {string} 过滤器处理后数据
 */
export function limitString (value, length) {
  if (value.length > 160) {
    return value.substring(0, 160) + '...'
  } else {
    return value
  }
}

const filters = {
  limitString
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
