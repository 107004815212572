import request from '@/utils/request'
export default {
  /**
   *ajax的封装（post）
   * @param funcUrl  请求的方法地址
   * @param argsData 入参
   */
  post: function (funcUrl, argsData, responseType) {
    const url = funcUrl
    const UA = navigator.userAgent
    const TOKEN = 'dreamtouch'
    return request({
      url: url,
      method: 'post',
      data: {
        args: argsData,
        token: TOKEN,
        deviceInfo: UA,
        channel: 'pc'
      },
      responseType
    })
  }
}
window.Date.prototype.format = function (format) {
  var date = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    'S+': this.getMilliseconds()
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1
        ? date[k] : ('00' + date[k]).substr(('' + date[k]).length))
    }
  }
  return format
}
