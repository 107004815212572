import Vue from 'vue'
import App from './App.vue'
import router from './router/.invoke/router'
import store from './store'
import './components/global'
import './services'
import './vendor/element'
import './vendor/swiper'
import './filters'
import './assets/style/theme/index.css'
import globalData from './constant'
import common from '../src/assets/common.js'
import axios from 'axios'
window.axios = axios
window.$common = common
Vue.prototype.$globalData = globalData
Vue.prototype.$common = common
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
