<template>
  <swiper class="banner-swiper" :options="swiperOption" :auto-update="true" v-if="bannerList.length">
    <swiper-slide v-for="(item, index) in bannerList" :key="index">
      <img class="banner-pic" :src="item.imageUrl" v-if="item.imageUrl"/>
    </swiper-slide>
    <!-- Add Pagination -->
    <div class="swiper-pagination banner-pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev" v-if="!isMobile"></div>
    <div class="swiper-button-next" slot="button-next" v-if="!isMobile"></div>
  </swiper>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Banner',

  data () {
    const that = this
    return {
      bannerList: [],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: true,
        on: {
          click () {
            const realIndex = this.realIndex
            that.handleClickSlide(realIndex)
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters(['isMobile'])
  },

  mounted () {
    this.getBanner()
  },

  methods: {
    getBanner () {
      window.$common.post('/api/banner/get-banner-list', {
        pageIndex: 0,
        pageSize: 0
      }).then(res => {
        if (res.retCode === this.$globalData.RET_CODE.SUCCESS) {
          this.bannerList = res.obj.list
        }
      })
    },
    handleClickSlide (reallyIndex) {
      const item = this.bannerList[reallyIndex]
      if (item.type === this.$globalData.bannerTypeObj.url && item.arriveUrl) {
        window.open(item.arriveUrl)
      } else if (item.type === this.$globalData.bannerTypeObj.bottom) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  }
}
</script>

<style scoped lang="less">
  // 大屏
  @media screen and (min-width: 750px) {
    .banner-swiper {
      height: 660px;
    }
    /deep/.banner-pagination .swiper-pagination-bullet {
      width:12px;
      height:12px;
      background: rgba(63,99,232);
      opacity: 0.3;
    }
    /deep/.banner-pagination .swiper-pagination-bullet-active {
      width:12px;
      height:12px;
      background: rgba(63,99,232);
      opacity: 0.9;
    }
  }
  // 小屏
  @media screen and (max-width: 750px) {
    .banner-swiper {
      height: 180px;
    }
    /deep/.swiper-pagination-bullets{
      bottom: 20px!important;
    }
    /deep/.banner-pagination .swiper-pagination-bullet {
      width:8px;
      height:8px;
      background: rgba(63,99,232);
      opacity: 0.3;
    }
    /deep/.banner-pagination .swiper-pagination-bullet-active {
      width:8px;
      height:8px;
      background: rgba(63,99,232);
      opacity: 0.9;
    }
  }
  .banner-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  .swiper-button-prev{
    left: 40px;
    transform: scale(1.5);
    background: url("../../images/banner/ic_home_banner_arrow_left.svg") no-repeat;
    background-position: center;
    background-size: 100%;
    outline: none;
    &:after{
      content: none;
    }
  }
  .swiper-button-next{
    right: 40px;
    transform: scale(1.5);
    background: url("../../images/banner/ic_home_banner_arrow_right.svg") no-repeat;
    background-position: center;
    background-size: 100%;
    outline: none;
    &:after{
      content: none;
    }
  }
</style>
