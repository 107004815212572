<template>
  <div class="header" :class="isMobile?'header-' + isMobile:''">
    <template v-if="isMobile">
      <div class="fixed-width flex-between" style="width: 100%">
        <img class="logo" :src="logoSrc"/>
        <img :src="require('@images/global/ic_common_navbar.svg')" @click="showTabs = true"/>
      </div>
      <el-drawer
        size="120px"
        :visible.sync="showTabs"
        direction="rtl">
        <div v-for="(item, index) in menuList" :key="index" class="tabs" :class="activeName==index?'active-tab':''" @click="beforeLeave(index.toString())">
          {{item.label}}
        </div>
      </el-drawer>
      <el-popover
        @hide="showMore"
        placement="bottom"
        trigger="click">
        <div>
          <div v-for="(item, index) in subMenuList" :key="index" class="menu-item" @click="beforeLeave">{{item.lable}}</div>
        </div>
        <img slot="reference" :src="require('@images/global/ic_common_more.svg')" class="more-btn" :class="isShowMore===true?'more-btn-show':isShowMore?'more-btn-hide':''" @click="isShowMore=true">
      </el-popover>
    </template>
    <template v-else>
      <div class="fixed-width flex-between">
        <img class="logo" :src="logoSrc"/>
        <el-tabs v-model="activeName" @tab-click="handleClick" :before-leave="beforeLeave">
          <el-tab-pane v-for="(item, index) in menuList" :key="index" :label="item.label" :name="index.toString()"></el-tab-pane>
        </el-tabs>
      </div>
    </template>
    <el-backtop style="right: 20px;bottom: 100px">
      <img :src="require('@images/global/ic_common_sidebar_top.svg')">
    </el-backtop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  data () {
    return {
      logoSrc: require('@images/global/pic_logo.png'), // logo图片本地地址
      menuList: [
        {
          label: '首页',
          path: '/index'
        },
        {
          label: '产品中心',
          path: ''
        },
        {
          label: '重大事件',
          path: ''
        },
        {
          label: '关于我们',
          path: ''
        },
        {
          label: '加入我们',
          path: ''
        }
      ],
      subMenuList: [
        {
          lable: '加入我们'
        },
        {
          lable: '联系我们'
        },
        {
          lable: '申请合作'
        },
        {
          lable: '下载试用'
        }
      ],
      activeName: '0',
      showTabs: false,
      isShowMore: null
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  mounted () {
  },
  destroyed () {
  },
  created () {
    this.activeName = this.menuList.findIndex(item => {
      return window.location.hash.indexOf(item.path) > -1
    }).toString()
  },
  methods: {
    showMore () {
      this.isShowMore = !(this.isShowMore || false)
    },
    // 切换选项
    handleClick () {
    },

    beforeLeave (activeName) {
      if (activeName !== '0') {
        this.$message.warning('功能暂未开放')
        return false
      }
      // const path = this.menuList[parseInt(activeName)].path
      // this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="less">
  .menu-item{
    font-size: 16px;
    color: #747EA3;
    margin-bottom: 15px;
    text-align: center;
    &:last-child{
      margin-bottom: 0;
    }
  }
  /deep/.el-drawer__header{
    padding: 17px 5px 17px 0;
    margin-bottom: 0;
  }
  .tabs{
    font-size: 16px;
    color: #747EA3;
    text-align: center;
    height: 48px;
    line-height: 48px;
  }
  .active-tab{
    color: #3F63E8;
    background: rgba(63,99,232,0.05);
    border-left: 5px solid #3F63E8;
  }
  .header {
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
    .flex-box(row, center, center);
    &-true{
      height: 52px;
      padding: 0 15px;
      .logo{
        height: 28px;
      }
    }
  }

  .logo {
    height: 40px;
  }

  /deep/.el-tabs__header{
    margin: 0;
  }
  /deep/.el-tabs__item {
    font-size: @font-size-16;
    color: @light-primary-color;
    &.is-active {
      color: @primary-color;
    }
  }
  /deep/.el-tabs__nav-wrap::after {
    height: 0;
  }
  /deep/.el-tabs__active-bar {
    height: 3px;
    background-image: linear-gradient(270deg, #489FFD 0%, #3727D3 100%);
    border-radius: 2px;
  }
  .more-btn{
    position: fixed;
    bottom: 25px;
    right: 16px;
    z-index: 100;
    transition: 0.5s;
    outline: none;
    border-radius: 50px;
    box-shadow: 0 2px 12px 0 rgba(35,58,145,0.20);
  }
  .more-btn-show{
    transform: rotate(315deg);
  }
  .more-btn-hide{
    transform: rotate(-315deg);
  }
</style>
