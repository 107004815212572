<template>
  <div class="product">
    <div class="fixed-width-container">
      <module-title title="产品中心"/>
      <el-radio-group class="choose-box" v-model="currentProduct.productId" @change="changeProduct">
        <el-radio-button v-for="(item, index) in productList" :disabled="item.disabled" :label="item.productId" :key="index">{{item.title}}</el-radio-button>
      </el-radio-group>
      <div :class="isMobile?'':'flex-between'">
        <el-image class="product-pic" fit="cover" :src="currentProduct.image" lazy></el-image>
        <div class="content-box">
          <div>
            {{currentProduct.content | limitString(160)}}
          </div>
          <div class="more-btn flex-center-item margin-top-20">查看更多  <img :src="morePic"></div>
        </div>
      </div>
    </div>
    <div class="message-boxes" :class="'message-boxes-' + isMobile" v-if="currentProduct.infoList.length">
      <img :src="require('@images/index/pic_home_bg_1.svg')" class="aside-pic" v-if="!isMobile">
      <div class="bg-white message-container" :class="'message-container-' + isMobile">
        <div class="fixed-width-container">
          <el-row :gutter="isMobile?20:40">
            <el-col :span="isMobile?12:6" v-for="(item, index) in currentProduct.infoList" :key="index" class="message-box" :class="'message-box-' + isMobile">
              <div class="main-msg flex-column-center" :class="'main-msg-' + isMobile"  @click="openDetail(item)">
                <img :src="item.icon">
                <span class="msg-main-title">{{item.title}}</span>
<!--                <div class="more-msg flex-column-center" v-if="index!==7">-->
<!--                  <img :src="item.icon" class="icon">-->
<!--                  <div class="msg-title">{{item.title}}</div>-->
<!--                  <div>{{item.content}}</div>-->
<!--                  <div class="more-white-btn flex-center-item margin-top-20">查看更多  <img :src="morePicBlue"></div>-->
<!--                </div>-->
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="message-boxes-null" v-else></div>
    <div class="fixed-dialog" v-if="detailDialog">
      <div style="background: #FFFFFF;border-radius: 8px 8px 8px 8px;width: 40%;margin: 0 auto;padding: 28px;top: 50%;transform: translateY(50%);" :style="isMobile?'width: 80%':'width: 40%'">
        <div style="margin-bottom: 18px;position: relative">
          <div style="color: #061549;font-size: 18px;font-weight: bold">救在身边-人人救 APP介绍</div>
          <div @click="detailDialog = false" style="position: absolute;right: 0;top: 0;cursor: pointer">
            <img style="width: 24px" :src="require('@/images/index/x-close@2x.png')">
          </div>
        </div>
        <div style="color: #38467B;font-size: 16px;text-align: left;line-height: 2">
          通过人人救APP，将急救志愿者统一集中到平台上。急救事件发生时，调度员可以发送消息求助就近的急救志愿者，志愿者可以比救护车更早到达患者位置，使患者更快得到专业的帮助，最大程度保证患者的抢救成功率。同时可通过平台将AED设备进行统一管理，对志愿者公布具体AED布局情况、位置，方便随时随地查找附近AED，提升AED使用率，社会救治率，保障公众生命健康安全。
          <br>主要功能：报名浙江急救志愿者招募；进行线上理论考试；报名线下急救级技能培训；查看附近AED设备；一键呼叫120；接收志愿急救任务；查看行业新闻和急救知识；查看呼叫、志愿任务、考试、培训等记录。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Product',

  data () {
    return {
      morePic: require('@images/global/ic_home_arrow_white.svg'),
      morePicBlue: require('@images/global/ic_home_arrow_blue.svg'),
      currentProduct: {},
      productList: [
        {
          productId: 1,
          disabled: false,
          title: '院前云急救调度系统',
          content: '院前云急救系统包括调度系统、患者呼叫移动端和急救人员移动端，各子系统之间信息共享互通。利用有线通信、GIS、GPS、视频监控、实时数据传输、语音和图像资源，结合各角色共同推进急救任务顺利进行。该系统通过患者云呼叫推送至调度端，调度人员对进行一系列询问、分配、发出指令等操作将最新消息推送至相关急救人员和车辆，获得快速响应后实施开展急救任务',
          image: require('@images/index/pic_home_cpzx_1.svg'),
          infoList: [
            {
              id: 1,
              title: '急救云调度系统',
              icon: require('@images/index/ic_home_cpzx_1.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 2,
              title: '浙里办浙江急救',
              icon: require('@images/index/ic_home_cpzx_2.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 3,
              title: '呼叫端',
              icon: require('@images/index/ic_home_cpzx_3.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 4,
              title: '救在身边-人人救',
              icon: require('@images/index/ic_home_volunteer.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 5,
              title: '车载端',
              icon: require('@images/index/ic_home_cpzx_5.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 6,
              title: '医疗端',
              icon: require('@images/index/ic_home_cpzx_6.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 7,
              title: '驾驶端',
              icon: require('@images/index/ic_home_cpzx_7.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 8,
              title: '查看更多',
              icon: require('@images/index/ic_home_cpzx_8.svg')
            }
          ]
        },
        {
          productId: 2,
          disabled: true,
          title: '急救质量监控系统',
          content: '该系统主要分为业务信息查询和业务统计分析二大功能。业务信息查询功能可实现急救中心呼叫记录、受理记录等相关信息查询；业务统计功能可实现对业务数据进行归纳整理分析，生成各类统计分析报表，为制定政策和质量控制提供依据和数据源。',
          image: require('@images/index/pic_home_cpzx_2.svg'),
          infoList: [
            {
              id: 1,
              title: '管理端',
              icon: require('@images/index/ic_home_cpzx_9.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 2,
              title: '急救中心后台管理系统',
              icon: require('@images/index/ic_home_cpzx_10.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 3,
              title: '省级管理平台',
              icon: require('@images/index/ic_home_cpzx_11.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            },
            {
              id: 4,
              title: '急救数字驾驶舱',
              icon: require('@images/index/ic_home_cpzx_12.svg'),
              content: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治'
            }
          ]
        }
      ],
      detailDialog: false
    }
  },

  computed: {
    ...mapGetters(['isMobile'])
  },

  created () {
    this.currentProduct = JSON.parse(JSON.stringify(this.productList[0]))
  },

  methods: {
    // 切换选择产品
    changeProduct (value) {
      const currentProduct = this.productList.filter(item => {
        return item.productId === value
      })[0]
      this.currentProduct = JSON.parse(JSON.stringify(currentProduct))
    },
    openDetail (item) {
      if (item.id === 4) {
        this.detailDialog = true
      } else {
        this.$message.warning('暂无详细信息')
      }
    }
  }
}
</script>

<style scoped lang="less">
  .product {
    text-align: center;
  }
  .el-radio-group{
    border-radius: 23px;
    box-shadow: 0 0 6px 0 rgba(63,99,232,0.20);
    overflow: hidden;
  }
  /deep/.el-radio-button {
     .el-radio-button__inner {
       border: none;
     }
   }
  // 大屏
  @media screen and (min-width: 750px) {
    .product-pic{
      width: 440px;
    }
    .choose-box{
      margin: 40px 0 50px;
    }
    .content-box{
      width: 570px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 10px 0 rgba(63,99,232,0.15);
      border-radius: 8px;
      font-size: 16px;
      text-align: left;
      padding: 30px;
      color: @font-primary-color;
      .more-btn{
        background-image: linear-gradient(181deg, #48B9FD 0%, #3642F3 100%);
        border-radius: 18px;
        font-size: 16px;
        color: #FFFFFF;
        padding: 7px 0;
        cursor: pointer;
        width: 125px;
        margin-top: 20px;
      }
    }
  }
  // 小屏
  @media screen and (max-width: 750px) {
    .product-pic{
      width: 80%;
      margin-bottom: 20px;
    }
    .choose-box{
      margin: 20px 0 20px;
    }
    /deep/.el-radio-button {
      .el-radio-button__inner {
        font-size: 13px;
        padding: 7px 10px;
      }
    }
    .content-box{
      background: rgba(255, 255, 255, 0.95);
      font-size: 14px;
      text-align: left;
      padding: 15px;
      color: @font-primary-color;
      .flex-box(column, null, center);
      .more-btn{
        background-image: linear-gradient(181deg, #48B9FD 0%, #3642F3 100%);
        border-radius: 20px;
        font-size: 13px;
        color: #FFFFFF;
        padding: 7px 15px;
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
  .more-white-btn{
    background: @white;
    border-radius: 18px;
    font-size: 14px;
    color: @primary-color;
    padding: 5px 20px;
    cursor: pointer;
  }
  .message-boxes{
    padding: 60px 0;
    &-true{
      padding: 0 15px;
      background: @white;
    }
    &-null{
      background: none;
      height: 150px;
    }
    .message-box {
      cursor: pointer;
      margin-bottom: 40px;
      &-true{
        margin-bottom: 15px;
      }
      .main-msg{
        position: relative;
        overflow: hidden;
        margin-top: 10px;
        height: 275px;
        box-shadow: 0 0 10px 0 rgba(63,99,232,0.15);
        border-radius: 8px;
        font-size: 22px;
        color: #061549;
        font-weight: bold;
        .msg-main-title{
          margin-top: 30px;
        }
        &-true{
          height: 108px;
          font-size: 14px;
          img{
            width: 55px;
          }
          .msg-main-title{
            margin-top: 10px;
          }
        }
      }
      .more-msg{
        height: 275px;
        box-shadow: 0 0 10px 0 rgba(63,99,232,0.15);
        padding: 0 25px;
        border-radius: 8px;
        background: #3F63E8;
        font-size: 14px;
        color: #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: 0.5s;
        transform: translateY(100%);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        .icon{
          width: 50px;
        }
        .msg-title{
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          padding: 10px 0;
        }
        .msg-main-title{
          font-size: 22px;
        }
      }
    }
    .message-box:hover {
      .more-msg {
        transform: translateY(0);
      }
    }
  }
  .message-container{
    padding: 30px 0 110px;
    &-true{
      padding: 0 0 58px;
    }
  }
  .fixed-dialog {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 111;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
  }
</style>
