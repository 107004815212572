<template>
  <div class="recruit-box flex-column-center" :class="isMobile?'recruit-box-' + isMobile:''">
    <div class="fixed-width-container">
      <div class="recruit-main-title">全国合作伙伴招募</div>
      <div class="recruit-sub-title">院前院内，互联互通，构建网络化、数据化的云端医疗急救</div>
      <!--        <div>留下您的联系方式，我们会尽快联系您</div>-->
      <!--        <div class="input-box">-->
      <!--          <el-input class="width-130 margin-right-30" placeholder="姓名（必填）"></el-input>-->
      <!--          <el-input class="margin-right-30 phone-input" placeholder="手机号（必填）"></el-input>-->
      <!--          <el-input class="margin-right-30 organization-input" placeholder="机构名称（必填）"></el-input>-->
      <!--          <el-select class="width-130 margin-right-30" placeholder="请选择省"></el-select>-->
      <!--          <el-select class="width-130 margin-right-30" placeholder="请选择市"></el-select>-->
      <!--          <div class="apply-btn" @click="apply">申请合作</div>-->
      <!--        </div>-->
      <div>请致电我们，让我们为您服务 <span class="phone-span">0571-81903089</span></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Recruit',
  computed: {
    ...mapGetters(['isMobile'])
  }
}
</script>

<style scoped lang="less">
  .recruit-box{
    color: @white;
    background: url("../../images/index/pic_common_recruit_bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
    padding: 80px 0;
    font-size: 22px;
    .recruit-main-title{
      font-size: 58px;
      font-weight: bolder;
      margin-bottom: 30px;
    }
    .recruit-sub-title{
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 40px;
      opacity: 0.9;
    }
    /deep/.el-input{
      font-size: 16px;
      .el-input__inner{
        border: none;
      }
    }
    .phone-input{
      width: 190px;
    }
    .organization-input{
      width: 310px;
    }
    .input-box{
      padding: 20px 0 40px;
    }
    .apply-btn{
      background-image: linear-gradient(181deg, #48B9FD 0%, #3642F3 100%);
      border-radius: 22px;
      font-size: 18px;
      padding: 10px 30px;
      display: inline-block;
      cursor: pointer;
    }
    .phone-span{
      font-size: 40px;
      opacity: 0.8;
      font-weight: bold;
    }
    &-true{
      font-size: 13px;
      padding: 28px 15px 20px;
      .recruit-main-title{
        font-size: 26px;
        margin-bottom: 10px;
      }
      .recruit-sub-title{
        font-size: 16px;
        margin-bottom: 10px;
      }
      .phone-span{
        font-size: 24px;
        display: inline-block;
      }
    }
  }
</style>
