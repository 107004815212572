const state = {
  message: null
}

const mutations = {
  SET_MESSAGE: (state, message) => {
    state.message = message
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
