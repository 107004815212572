<template>
  <div class="produce-mould">
    <div class="produce-mould-title">{{data.title}}</div>
    <p>{{data.introduce}}</p>
    <div v-for="(item,index) in data.list" :key="index">
      <div>icon</div>
      <div class="produce-mould-item-title">{{item.title}}</div>
      <p>{{item.content}}</p>
      <el-image v-if="item.produceImage" :src="item.produceImage"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mould',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      produceImage: require('@images/produce/ic_common_sidebar_joinus.png')
    }
  }
}
</script>

<style lang="less" scoped>
.produce-mould {
  font-size: 16px;
  color: #38467B;
  .produce-mould-title {
    font-size: 22px;
    color: #061549;
    font-weight: bolder;
  }
  .produce-mould-item-title {
    font-size: 18px;
    color: #061549;
    font-weight: bold;
  }
}
</style>
